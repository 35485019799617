import React, {
    createContext,
    useEffect,
    useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import authAPI from 'src/services/AuthService';
import {APP} from "../config";

const initialAuthState = {
    isAuthenticated: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            return {
                ...state,
                isInitialised: true
            };
        }
        default: {
            return {...state};
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT'
});

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    
    useEffect(() => {
        const initialise = async () => {
            
            const result = await authAPI.checkAuth();
            if (result.data === 'ISAUTH') {
                window.location.href = APP.opusplus;
            }
            
            dispatch({
                type: 'INITIALISE',
                payload: {}
            });
            
        };
        
        initialise();
    }, []);
    
    if (!state.isInitialised) {
        return <SplashScreen/>;
    }
    
    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
