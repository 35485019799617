import metadata from 'src/scripts/metadata.json';


export const APP_VERSION = `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`;

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
    LIGHT: 'LIGHT',
    ONE_DARK: 'ONE_DARK',
    UNICORN: 'UNICORN'
};

export const REGEXPS = {
    PWD: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(.*){8,}$/
};

export const GENRE_OPTIONS = [
    {
        id: 'F',
        name: 'Femmina'
    },
    {
        id: 'M',
        name: 'Maschio'
    }
];

export const CONTACT_TYPES = [
    {
        ID: 'P',
        NAME: 'Telefono'
    },
    {
        ID: 'E',
        NAME: 'E-mail'
    }
];

export const CONTACT_LABELS = [
    {
        ID: 'C',
        NAME: 'Casa'
    },
    {
        ID: 'L',
        NAME: 'Lavoro'
    },
    {
        ID: 'A',
        NAME: 'Altro'
    }
];



export const sortOptions = [
    {
        value: 'updatedAt|desc',
        label: 'Last update (newest first)'
    },
    {
        value: 'updatedAt|asc',
        label: 'Last update (oldest first)'
    },
    {
        value: 'orders|desc',
        label: 'Total orders (high to low)'
    },
    {
        value: 'orders|asc',
        label: 'Total orders (low to high)'
    }
];


export const TEXT = {
    IT: {
        ROLEID: {
            StringTooShort: "Ruolo richiesto"
        },
        NAME: {
            StringTooShort:
                'Nome richiesto.',
            TypeNotValid: 'Nome non valido',
            RegexNotValid: 'Nome non valido',
            GenericError: 'Errore generico'
        },
        SURNAME: {
            StringTooShort: 'Cognome richiesto.',
            TypeNotValid: 'Cognome non valido',
            RegexNotValid: 'Cognome non valido',
            GenericError: 'Errore generico'
        },
        CITYID: {
            StringTooShort: 'Città richiesta.'
        },
        FISCALCODE: {
            StringTooShort: 'Codice fiscale troppo corto.',
            TypeNotValid: 'Codice fiscale non valido',
            RegexNotValid: 'Codice fiscale non valido',
            GenericError: 'Errore generico',
            FiscalCodeExists: 'Codice fiscale esistente'
        },
        ACCOUNTPWD: {
            StringTooShort: 'Password troppo corta.',
            TypeNotValid: 'Tipo non valido',
            RegexNotValid: 'Password non valida',
            GenericError: 'Errore generico'
        },
        
        ACCOUNTEMAIL: {
            StringTooShort: 'Email troppo corta.',
            TypeNotValid: 'Tipo non valido',
            RegexNotValid: 'Campo non valido',
            EmailNotValid: 'Email non valida ',
            EmailExists: 'Email esistente'

        },
        BIOMETRICDATAHEIGHT: {
            TypeNotValid: 'Altezza non valida',

        },
        BIOMETRICDATAWEIGHT: {
            TypeNotValid: 'Peso non valido',
        },
        BIOMETRICDATAWAIST: {
            TypeNotValid: 'Circonferenza non valida',
        },
        EMAIL: {
            AccountNotExists: 'Account non esiste',
            PasswordNotMatch: 'Password non valida'
        },
        PATIENTID: {
            StringTooShort: 'Paziente obbligatorio'
        },
        STATUSID: {
            StringTooShort: 'Stato obbligatorio'
        },
        TEXT: {
            StringTooShort: 'Testo domanda obbligatorio'
        },
        CATEGORYID: {
            NotValidCategory: 'Categoria obbligatoria',
            StringTooShort: 'Categoria obbligatoria'
        },
        SPECID: {
            StringTooShort: 'Specializzazione obbligatoria'
        },
        SCREENINGID: {
            NotValid: 'Screening obbligatorio',
        },
        UNITID: {
            StringTooShort: 'Unità obbligatoria'
        },
        MODEID: {
            NotValidMode: 'Modalità obbligatoria'
        },
        DOCTORID: {
            StringTooShort: 'Dottore obbligatorio',
            TypeNotValid: 'Dottore richiesto'
        },
        PWD: {
            PwdNotValid: 'Password non valida',
            StringTooShort: 'Password richiesta.'
        },
    }
};


export const ALERTVALUES = [
    {
        id: 'GTE',
        text: 'Maggiore di'
    },
    {
        id: 'LTE',
        text: 'Minore di'
    },
    {
        id: 'EQ',
        text: 'Uguale a'
    }
];

export const VARIABLEDATA = [
    {
        id: '_Fall',
        text: 'Caduta'
    },
    {
        id: '_Step',
        text: 'Passi'
    },
    {
        id: '_BloodPressure',
        text: 'Pressione sanguinia'
    },
    {
        id: '_HeartRate',
        text: 'Battito cardiaco'
    },
    {
        id: '_OxygenSaturation',
        text: 'Saturazione'
    },
    {
        id: '_ECG',
        text: 'ECG'
    }
];

export const REFERENTS = [
    {
        id: 'family-doctor',
        text: 'Dottore di famiglia'
    },
    {
        id: 'referent',
        text: 'Referente'
    }
];

