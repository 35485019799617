

export const appConfig = {
    name: 'opusplus'
};

export const API = {
    url: process.env.REACT_APP_API_URL
};

export const APP = {
    opusplus: process.env.REACT_APP_APP_URL,
};

