import React from 'react';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import {create} from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import {SnackbarProvider} from 'notistack';
import {jssPreset, StylesProvider, ThemeProvider} from '@material-ui/core';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import useSettings from 'src/hooks/useSettings';
import {createTheme} from 'src/theme';
import routes, {renderRoutes} from 'src/routes';
import AppContextProvider from './components/AppContextProvider';
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import {LocalizationProvider} from "@mui/lab";

const jss = create({plugins: [...jssPreset().plugins, rtl()]});
const history = createBrowserHistory();

const App = () => {
    const {settings} = useSettings();
    
    const theme = createTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme
    });
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ThemeProvider theme={theme}>
                <StylesProvider jss={jss}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <SnackbarProvider
                            dense
                            maxSnack={2}
                        >
                            <Router history={history}>
                                <AppContextProvider>
                                    <GlobalStyles/>
                                    <ScrollReset/>
                                    {renderRoutes(routes)}
                                </AppContextProvider>
                            </Router>
                        </SnackbarProvider>
                    </MuiPickersUtilsProvider>
                </StylesProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
};

export default App;
