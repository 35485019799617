import {API} from 'src/config';

export const doGet = async url => {
    const requestOptions = {
        method: 'GET',
        credentials: 'include'
    };
    const result = await fetch(`${API.url}/api/${url}`, requestOptions);
    return result.json();
};

export const doPost = async (url, body) => {
    
    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(body),
        credentials: 'include'
    };
    const result = await fetch(`${API.url}/api/${url}`, requestOptions);
    return result.json();
};

